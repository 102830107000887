import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Auth';

const AdminRoute = ({ component: RouteComponent, ...rest }) => {

    const {isAdmin} = useContext(AuthContext);    

    return (
        
        <Route
            {...rest}
            render={routeProps =>
                !!isAdmin ? (
                    <RouteComponent {...routeProps} />
                ) : (
                    <Redirect to={'/404'} />
                )
            }
        />
    );
}



export default AdminRoute;