import React from 'react';
import { Link } from 'react-router-dom';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import app from '../../firebase';

import './Toolbar.css';

const toolbar = props => {

    app.auth().onAuthStateChanged(user => {
        
        if (user) {

            let usertools = document.getElementsByClassName('usertools');

                for (let i = 0; i < usertools.length; i++) {
                    usertools[i].classList.remove('hidden');
                }

            let logintab = document.getElementsByClassName('logintab');

                for (let i = 0; i < logintab.length; i++) {
                    logintab[i].classList.add('hidden');
                }

        } else {

            let usertools = document.getElementsByClassName('usertools');

                for (let i = 0; i < usertools.length; i++) {
                    usertools[i].classList.add('hidden');
                }
                
            let logintab = document.getElementsByClassName('logintab');

                for (let i = 0; i < logintab.length; i++) {
                    logintab[i].classList.remove('hidden');
                }
        }
      });
    
    return (
    <header className="toolbar shadow-md">
        <nav className="toolbar_navigation">
            <div>
                <DrawerToggleButton click={props.drawerClickHandler} />
            </div>
            <div className="toolbar_logo"><Link to="/" onClick={props.selectMap} id="home">HOLYSMOKES</Link></div>
            <div className="spacer" />
            <div className="toolbar_navigation-items">
                <ul>
                    <li><Link to="/" onClick={props.selectMap} id="inferno">Inferno</Link></li>
                    <li><Link to="/" onClick={props.selectMap} id="train">Train</Link></li>
                    <li><Link to="/" onClick={props.selectMap} id="mirage">Mirage</Link></li>
                    <li><Link to="/" onClick={props.selectMap} id="nuke">Nuke</Link></li>
                    <li><Link to="/" onClick={props.selectMap} id="overpass">Overpass</Link></li>
                    <li><Link to="/" onClick={props.selectMap} id="dust2">Dust II</Link></li>
                    <li><Link to="/" onClick={props.selectMap} id="vertigo">Vertigo</Link></li>
                    <li><Link to="/" onClick={props.selectMap} id="cache">Cache</Link></li>
                    <li><Link to="/" onClick={props.selectMap} id="cobblestone">Cobblestone</Link></li>
                    
                </ul>
            </div>
            <div className="spacer" />
            <div className="toolbar_navigation-items">
                
                <ul className="usertools hidden">
                    <li><Link to="/upload">Upload</Link></li>
                    <li><Link to="/profile">My Profile</Link></li>
                    <li><button className="toolbar_button" onClick={() => app.auth().signOut()}>Logout</button></li>
                </ul>
                <ul className="logintab">
                    <li><Link to="/auth">Login / Register</Link></li>
                </ul>
                
            </div>
            
        </nav>
    </header>
    )
};

export default toolbar;