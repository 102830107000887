import React, { Component } from 'react';
import app from '../../firebase';
import { storage, db } from '../../firebase';
import swal from 'sweetalert';
import Dropzone from 'react-dropzone-uploader';
import { Redirect } from "react-router-dom";

import './Upload.css';
import './Dropzone.css';

const videoMaxSize = 50000000 // bytes
  
class UploadPage extends Component {

    state = {
        title: null,
        description: null,
        map: null,
        utility: null,
        type: null,
        tickrate: null,
        selectedFile: null,
        userid: null,
        redirect: null,
        userName: null,
        uploadProgress: null,
        uploadActive: false
    }

    constructor(props) {
        super(props);
        this.authListener = this.authListener.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.authListener()
    }

    authListener = () => {
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                let currentUser = app.auth().currentUser;
                this.setState({ 
                    userid: currentUser.uid,
                    userName: currentUser.displayName
                });
            }
        });
    };

    handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        
        this.setState({
          [name]: value
        });
    }

    firestoreAutoId = () => {
        const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      
        let autoId = ''
      
        for (let i = 0; i < 20; i++) {
          autoId += CHARS.charAt(
            Math.floor(Math.random() * CHARS.length)
          )
        }
        return autoId
    }

    tooManyUploads = () => {

        let tmpClips = [] 

        db.collection('clips')
        .where('userid', '==', this.state.userid)
        .where('verified', '==', false)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                tmpClips.push(doc.data());
            });
            if (tmpClips.length > 4) {
                return true
            } else {
                return false
            }
        })
        
        
    }

    // UPLOAD STUFF

    fileSelectedHandler = ({meta}, status, file) => {

        let num = videoMaxSize/1000000
        let numFixed = num.toFixed(0);

        if(status === 'error_file_size') {
            swal({
                icon: "warning",
                title: "File is too large",
                text: "File size is over the accepted " + numFixed + "Mb"
            })
        }
        
        if (status.startsWith('error')) {
            swal({
                icon: "error",
                title: "Error",
                text: "There was an error please refresh and try again"
            })
        }

        if(status === 'rejected_max_files') {
            swal({
                icon: "warning",
                title: "Too many files",
                text: "Only one file at a time is accepted"
            })
        }
        if(status === 'rejected_file_type') {
            swal({
                icon: "warning",
                title: "Wrong file type",
                text: "Only videos are accepted"
            })
        }

        if(status === 'done') {
            this.setState({
                selectedFile: file[0].file
            })
        }
    }

    fileUploadHandler = (e) => {

        e.preventDefault();

        if(this.tooManyUploads()) {

            swal({
                icon: "warning",
                title: "Please wait",
                text: "You have too many unverified clips at the moment"
            })

        } else {

            if(!this.state.description || !this.state.map || !this.state.title || !this.state.tickrate || !this.state.type  || !this.state.utility) { 

                swal({
                    icon: "warning",
                    title: "Check the fields",
                    text: "Please fill in all the fields to upload"
                })

            } else {

                if (this.state.selectedFile) {

                    let storageRef = storage.ref();

                    let file = this.state.selectedFile;

                    let uid = this.firestoreAutoId();

                    // Upload file and metadata to the object 'unverified/*FILENAME*'
                    let uploadTask = storageRef.child('clips/' + uid).put(file);

                    // Listen for state changes, errors, and completion of the upload.
                    uploadTask.on('state_changed',
                        (snapshot) => {
                            
                            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            
                            this.setState({
                                uploadActive: true,
                                uploadProgress: Math.floor(progress)
                            })

                            swal({
                                title: "Uploading...",
                                text: this.state.uploadProgress + "%",
                                imageUrl: "images/ajaxloader.gif",
                                buttons: false,
                                allowOutsideClick: false
                              });

                            switch (snapshot.state) {
                                case 'paused':
                                    console.log('Upload is paused');
                                    break;
                                case 'running':
                                    console.log('Upload is running');
                                    break;
                                default:
                                    // No default case
                            }
                        }, (err) => {

                            // A full list of error codes is available at
                            // https://firebase.google.com/docs/storage/web/handle-errors
                            switch (err.code) {
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    break;
                            
                                case 'storage/canceled':
                                    // User canceled the upload
                                    break;
                            
                                case 'storage/unknown':
                                    // Unknown error occurred, inspect error.serverResponse
                                    break;
                                default:
                                    // No default case
                            }
                        }, () => {
                            // Upload completed successfully, now we can get the download URL
                            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {

                                console.log('File available at', downloadURL);
                                
                                // Add clip information to firestore
                                db.collection('clips').doc(uid).set({
                                    clipid: uid,
                                    title: this.state.title,
                                    description: this.state.description,
                                    map: this.state.map,
                                    utility: this.state.utility,
                                    type: this.state.type,
                                    tickrate: this.state.tickrate,
                                    url: downloadURL,
                                    verified: false,
                                    userid: this.state.userid,
                                    uploader: this.state.userName
                                })
                                .then(() => {
                                    swal({
                                        icon: "success",
                                        title: "Success!",
                                        text: "Your clip has been submitted for review!"
                                    }).then(() => {
                                        this.setState({
                                            redirect: '/'
                                        })
                                    })
                                })
                                .catch((error) => {
                                    console.error("Error adding document: ", error);
                                    swal({
                                        icon: "error",
                                        title: "Error",
                                        text: "There was an error please refresh and try again"
                                    });
                                });
                        });
                    })  
                } else {
                    swal({
                        icon: "warning",
                        title: "File missing",
                        text: "Please select a file to upload"
                    })
                }  
            }
        }

        

    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div className="box shadow-lg">
                <div className="upload-container">
                    <form className="upload-form" autoComplete="off" onSubmit={this.fileUploadHandler}>
                        <h1 className="auth-title">Upload a clip</h1>
                        
                        <div className="attention">
                            Be sure to read the acceptance criteria!
                        </div>

                        <h6 className="upload-item-name">Video file</h6>
                        <Dropzone 
                            onChangeStatus={this.fileSelectedHandler}
                            maxFiles={1}
                            accept="video/*"
                            maxSizeBytes={videoMaxSize}
                            multiple={false}
                        />

                        <div id="form-error" className="form-error"/>

                        <div className="form-control">
                            <h6 className="upload-item-name">Title</h6>
                            <input type="text" name="title" onChange={this.handleInputChange} />
                        </div>

                        <div className="form-control">
                            <h6 className="upload-item-name">Description</h6>
                            <textarea rows="3" name="description" onChange={this.handleInputChange} />
                        </div>
                        <div className="form-control">
                            <h6 className="upload-item-name">Tickrate</h6>
                            <div className="selectdiv">
                                <select className="upload-select" name="tickrate" onChange={this.handleInputChange} defaultValue={'DEFAULT'}>
                                    <option value="DEFAULT" disabled> -- select an option -- </option>
                                    <option class="others" value="64/128">64 & 128</option>
                                    <option class="others" value="128">128</option>
                                    <option class="others" value="64">64</option>                                    
                                </select>   
                            </div>
                        </div>
                        <div className="form-control">
                            <h6 className="upload-item-name">Map</h6>
                            <div className="selectdiv">
                                <select className="upload-select" name="map" onChange={this.handleInputChange} defaultValue={'DEFAULT'}>
                                    <option value="DEFAULT" disabled> -- select an option -- </option>
                                    <option class="others" value="inferno">Inferno</option>
                                    <option class="others" value="train">Train</option>
                                    <option class="others" value="mirage">Mirage</option>
                                    <option class="others" value="nuke">Nuke</option>
                                    <option class="others" value="overpass">Overpass</option>
                                    <option class="others" value="dust2">Dust II</option>
                                    <option class="others" value="vertigo">Vertigo</option>
                                    <option class="others" value="cache">Cache</option>
                                    <option class="others" value="cobblestone">Cobblestone</option>
                                    
                                </select>   
                            </div>
                        </div>
                        <div className="form-control">
                            <h6 className="upload-item-name">Utility / Type</h6>
                            <div className="selectdiv">
                                <select className="upload-select" name="utility" onChange={this.handleInputChange} defaultValue={'DEFAULT'}>
                                    <option value="DEFAULT" disabled> -- select an option -- </option>
                                    <option class="others" value="smoke">Smoke grenade</option>
                                    <option class="others" value="molotov">Molotov</option>
                                    <option class="others" value="flash">Flashbang</option>
                                    <option class="others" value="grenade">HE Grenade</option>
                                    <option class="others" value="boost">Boost</option>
                                    <option class="others" value="wallbang">Wallbang</option>
                                    
                                </select>   
                            </div>
                        </div>
                        <div className="form-control">
                            <h6 className="upload-item-name">Style</h6>
                            <div className="selectdiv">
                                <select className="upload-select" name="type" onChange={this.handleInputChange} defaultValue={'DEFAULT'}>
                                    <option value="DEFAULT" disabled> -- select an option -- </option>
                                    <option class="others" value="stationary">Stationary</option>
                                    <option class="others" value="jump">Jump</option>
                                    <option class="others"value="running">Running</option>
                                    <option class="others" value="running-jump">Running jump</option>
                                    <option class="others" value="other">Other</option>
                                    
                                </select>   
                            </div>
                        </div>

                        <div className="form-actions">
                            <button className="upload-button" type="submit">Upload</button>
                        </div>

                        
                    </form>
                </div>       
            </div>
        );
    }
}

export default UploadPage;