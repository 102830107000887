import React , { Component } from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import './App.css';

//page import
import MainPage from './pages/Main'
import AuthPage from './pages/Auth/Auth'
import UploadPage from './pages/Upload/Upload';
import ProfilePage from './pages/Profile/Profile';
import NotFound from './pages/NotFound';
import AdminPage from './pages/Admin/Admin'
import ClipPage from './pages/Clip/Clip';
//import MapPage from './pages/Map';

//component import
import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import PrivateRoute from './auth/PrivateRoute';
import AdminRoute from './auth/AdminRoute';

class App extends Component {

  state = {
    sideDrawerOpen: false,
    selectedMap: 'home'
  };

  constructor(props) {
    super(props);
    this.selectMap = this.selectMap.bind(this)
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  selectMap = (event) =>  {
    this.setState({ 
      selectedMap: event.target.id,
      sideDrawerOpen: false
    })
  }

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler}/>;   
    }

    return (
    <div className='App' style={{height: '100%'}}>
       <Toolbar 
       drawerClickHandler={this.drawerToggleClickHandler}
       selectMap={this.selectMap}
       />
       <SideDrawer 
       show={this.state.sideDrawerOpen}
       selectMap={this.selectMap}
       click={this.backdropClickHandler}
       />
       {backdrop}
       <main className="main">
        <Switch>
          <Route exact path='/' render={(props) => <MainPage {...props} selectMap={this.state.selectedMap}/> } />
          <Route path='/auth' component={AuthPage} />
          <Route path='/clip/:id' component={ClipPage} />
          <PrivateRoute path='/profile' component={ProfilePage} />
          <PrivateRoute path='/upload' component={UploadPage} />
          <AdminRoute path='/johtaja' component={AdminPage} />
          <Route component={NotFound} />
        </Switch>          
       </main>
    </div>
  );
  }
};

export default App;
