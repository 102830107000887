import React, { Component } from 'react';
import { db } from '../firebase';
import InfiniteScroll from 'react-infinite-scroll-component';
import Modal from '../components/Modal/Modal'

// Logo import

import infernoIcon from '../assets/map-icons/map_icon_de_inferno.svg';
import cacheIcon from '../assets/map-icons/map_icon_de_cache.svg';
import cobblestoneIcon from '../assets/map-icons/map_icon_de_cobblestone.svg';
import dust2Icon from '../assets/map-icons/map_icon_de_dust2.svg';
import mirageIcon from '../assets/map-icons/map_icon_de_mirage.svg';
import nukeIcon from '../assets/map-icons/map_icon_de_nuke.svg';
import overpassIcon from '../assets/map-icons/map_icon_de_overpass.svg';
import trainIcon from '../assets/map-icons/map_icon_de_train.svg';
import vertigoIcon from '../assets/map-icons/map_icon_de_vertigo.svg';
import copyLink from '../assets/icons/copy_link.png'

class MainPage extends Component {

    state = {
        loading: true,
        clips: [],
        selectMap: 'home',
        last: Object,
        infiLoadCount: 35,
        mapIcon: null,
        show: false,
        chosenClip: null
    }

    componentDidMount() {
        this.getScreenWidth()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectMap !== prevProps.selectMap) {
            this.changeMapValue();
          }
    }

    static getDerivedStateFromProps(props, state) {
        // Any time the current map changes,
        // Reset any parts of state that are tied to that map.
        if (props.selectMap !== state.selectMap) {
          return {
              selectMap: props.selectMap,
          }
        }
        return null;
    }

    changeMapValue(){
        this.setState({
            map: this.props.selectMap
        })
        this.getClips();
    }

    getScreenWidth() {
        let screenWidth = window.screen.width
        let loadCount = 8

        if(screenWidth < 555) {
            loadCount = 8
        } else if (554 < screenWidth < 767) {
            loadCount = 20
        } else {
            loadCount = 35
        }

        this.setState( { infiLoadCount: loadCount } );

        if (this.props.selectMap) {
            this.changeMapValue()
        } else {
            this.getClips()
        }
    }

    showModal() {
        this.setState({
            show: !this.state.show,
        })
    }

    getClips = () => {
        let set = this

        let first

        if (this.state.selectMap === 'home') {
            first = db.collection('clips').where('verified', '==', true).limit(this.state.infiLoadCount)
        } else if (!this.state.selectMap) {
            first = db.collection('clips').where('verified', '==', true).limit(this.state.infiLoadCount)
        } else {
            first = db.collection('clips').where('verified', '==', true).where('map', '==', this.state.selectMap).limit(this.state.infiLoadCount)
        }
        first.onSnapshot((documentSnapshots) => {
            // Get the last visible document
            let lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
            //initiate local list 
            let clips = [];
            documentSnapshots.forEach(doc =>  {
              clips.push({ data: doc.data(), id: doc.id})
            });
            set.setState({ clips, last: lastVisible });
        }); 
    }

    InfScroll = () => {
        let set = this
        let last = this.state.last

        let next
    
        if (this.state.selectMap === 'home') {
            next = db.collection('clips').where('verified', '==', true).startAfter(last).limit(this.state.infiLoadCount)
        } else if (!this.state.selectMap) {
            next = db.collection('clips').where('verified', '==', true).startAfter(last).limit(this.state.infiLoadCount)
        } else {
            next = db.collection('clips').where('verified', '==', true).where('map', '==', this.state.selectMap).startAfter(last).limit(this.state.infiLoadCount)
        }

        next.onSnapshot(querySnapshot => {
            let lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

            if(lastVisible == null) {
                set.setState( { loading: false } );  
            }
            
            querySnapshot.forEach(doc => {
                this.state.clips.push( { data: doc.data(), id: doc.id } )  
            });
            set.setState( { last: lastVisible } );   
        });
      }

    setMapIcon = () => {
        if(this.state.selectMap === 'home' || !this.state.selectMap ) {
            return
        } else if (this.state.selectMap === 'inferno'){
            return <img alt="" className="mapIcon" src={infernoIcon} />
        } else if (this.state.selectMap === 'train'){
            return <img alt="" className="mapIcon" src={trainIcon} />
        } else if (this.state.selectMap === 'mirage'){
            return <img alt="" className="mapIcon" src={mirageIcon} />
        } else if (this.state.selectMap === 'nuke'){
            return <img alt="" className="mapIcon" src={nukeIcon} />
        } else if (this.state.selectMap === 'overpass'){
            return <img alt="" className="mapIcon" src={overpassIcon} />
        } else if (this.state.selectMap === 'dust2'){
            return <img alt="" className="mapIcon" src={dust2Icon} />
        } else if (this.state.selectMap === 'vertigo'){
            return <img alt="" className="mapIcon" src={vertigoIcon} />
        } else if (this.state.selectMap === 'cache'){
            return <img alt="" className="mapIcon" src={cacheIcon} />
        } else if (this.state.selectMap === 'cobblestone'){
            return <img alt="" className="mapIcon" src={cobblestoneIcon} />
        }
    }

    handleThumbClick(thumbId) {

        this.setState( { chosenClip: thumbId } );

        this.showModal()
    }


    render() {
        return (
            <div className="feedcontainer"> 

            {this.state.show ? <Modal onClose={() => this.showModal()} show={this.state.show} clip={this.state.chosenClip}/> : null}

                <div id="clips" className="clip_gallery">
                    <div className="title">
                        <div className="titleIcon">{this.setMapIcon()}</div>
                        <div><h1 className="titleText">{this.props.selectMap}</h1></div>
                    </div>
                    
                    <InfiniteScroll
                    dataLength={this.state.clips.length} //This is important field to render the next data
                    next={this.InfScroll}
                    hasMore={this.state.loading}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                        <b>...</b>
                        </p>
                    }
                    >
                    <div class="list-items">
                        {this.state.clips.map((data, index) => 
                        <div className="clip_content" key={index} >   
                            <div className="clip_thumbnail" onClick={() => this.handleThumbClick(data.data.clipid)}>
                                <img
                                src={data.data.publicThumbUrl}
                                alt={data.data.clipid}
                                />
                                <div className="clip_map">{data.data.map}</div>
                                <div className="clip_tick">{data.data.tickrate}</div>
                            </div>
                            <div className="clip_details">
                                <div className="clip_title">{data.data.title}</div>
                                <span class="copy-link" onClick={() => navigator.clipboard.writeText(window.location.href + "clip/" + data.data.clipid)}>
                                    <img class="copy-link-svg" alt="" src={copyLink}/> Copy Link
                                </span>
                                <div className="detail_wrapper">   
                                    {data.data.utility}
                                </div>
                            </div>
                                
                        </div>)}
                    </div>
                    </InfiniteScroll>
                    
                    
                    
                </div>
            </div>
        );
    }
}

export default MainPage;