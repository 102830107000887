import React, { useEffect, useState } from 'react';
import app from '../firebase';

export const AuthContext = React.createContext();

//boolean check and based on that give route access

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [isAdmin, setAdmin] = useState(null);
  const [pending, setPending] = useState(true)
  

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        user.getIdTokenResult().then(idTokenResult => {
          setAdmin(idTokenResult.claims.admin)
          setPending(false)
        })
      } else {
        setPending(false);
      }
    })
  }, []);

  if(pending){
    //TODO: Tähän joku nätimpi loading setti
    return <>Loading...</>
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAdmin
      }}>
      {children}
    </AuthContext.Provider>
  );
};