import React, { Component } from 'react';
import app from '../../firebase';
import swal from 'sweetalert';

import './Profile.css';


class ProfilePage extends Component {
    state = {
        user: null,
        email: null,
        verified: null
    }

    constructor(props) {
        super(props);
        this.authListener = this.authListener.bind(this);
    }

    componentDidMount() {
        this.authListener();
    }

    authListener = () => {
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ 
                    user: user.displayName,
                    email: user.email,
                    verified: JSON.stringify(user.emailVerified)
                });
            } else {
                this.setState({ user: null })
            }
        });
    };

    changePassword = () => {
        let emailAddress = this.state.email;

        swal({
            title: "Change password?",
            text: "A link will be sent to your email.",
            icon: "info",
            buttons: true,
          }).then((willReset) => {

              if (willReset) {

                app.auth().sendPasswordResetEmail(emailAddress).then(() => {
                    // Email sent.
                    swal("An email with a password reset link has been sent to: " + emailAddress, {
                        icon: "success",
                      });
                    console.log('Email sent');
                })
                .catch((err) => {
                    // An error happened.
                    //console.log(err);
                });  
              }
          });
    }

    deleteUser = () => {

        let user = app.auth().currentUser;

        swal({
            title: "Delete your account?",
            text: "Once deleted, you will not be able to login!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {

            if (willDelete) {

                swal({
                    title: "ARE YOU SURE?",
                    text: "THERE IS NO COMING BACK!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((confirmDelete) => {

                    if (confirmDelete) {

                        user.delete().then(() => {
                            // User deleted.
                            swal("Your user has been deleted.", {
                                icon: "success",
                            });
                        })
                        .catch((err) => {
                            // An error happened.
                            //console.log(err);
                        });
                    }
                });
            }
        });
    }


    render() {
        return (
            <div className="box shadow-lg">
                <div className="profile-container">
                    <h1 className="profile-title">My Profile</h1>
                    <div className="profile-items">
                        <div className="profile-item">
                            <h6 className="profile-item-name">Dislpay Name</h6>
                            <h5 className="profile-item-subname">{this.state.user}</h5>
                        </div>
                        <div className="profile-item">
                            <h6 className="profile-item-name">E-Mail</h6>
                            <h5 className="profile-item-subname">{this.state.email}</h5>
                        </div>
                        <div className="profile-item">
                            <h6 className="profile-item-name">Verified</h6>
                            <h5 className="profile-item-subname">{this.state.verified}</h5>
                        </div>
                        <div className="button-clips">
                                <button className="clips-button">My Clips</button>
                        </div>
                        <div className="button-row">
                            <div className="button-col">
                                <button className="chpw-button" onClick={this.changePassword}>Change Password</button>
                            </div>
                            <div className="button-col">
                                <button className="delete-button" onClick={this.deleteUser}>Delete account</button>
                            </div>
                        </div>
                    </div>                                     
                </div>       
            </div>
        );
    }
}

export default ProfilePage;