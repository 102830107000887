import React, { Component } from 'react';
import ReactPlayer from 'react-player'
import { db } from '../../firebase';

import "./Modal.css";
export default class Modal extends Component {

    state = {
        clipInfo: []
    }

    onClose = e => {

        if(e.target.id === "closemodal") {
            this.props.onClose && this.props.onClose(e);
            this.setState({
                clipInfo: []
            })
        }
        
    };

    componentDidMount() {
        if (this.props.clip) {
            document.body.style.overflow = 'hidden';
            let docRef = db.collection('clips').doc(this.props.clip)

            docRef.get().then((doc) => {
                if (doc.exists) {
                    this.setState({
                        clipInfo: doc.data()
                    })
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
        } else {
            // doc.data() will be undefined in this case
            console.log("Props empty");
        }
        
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div id="modal">
                <div className="modal-wrapper shadow-lg" onClick={this.onClose} id="closemodal">
                <div className="modal-content">
                <div className="content">{this.props.children}</div>
                    <div className="clip-header">
                    <h2 className="single_title">{this.state.clipInfo.title}</h2>
                    <div className="previous round" onClick={this.onClose} id="closemodal">&#8249;</div>
                    </div>
                    
                        <div className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                url={this.state.clipInfo.publicUrl}
                                width='100%'
                                height='100%'
                                controls={true}
                                playing={true}
                            />
                        </div>
                        <div className="single_details">
                            
                            <div className="single_row">
                                <div className="single_utility single_col">{this.state.clipInfo.map}</div>
                                <div className="single_col">•</div>
                                <div className="single_type single_col">{this.state.clipInfo.tickrate}</div>
                                <div className="single_col">•</div>
                                <div className="single_tick single_col">{this.state.clipInfo.utility}</div>
                                <div className="single_col">•</div>
                                <div className="single_map single_col">{this.state.clipInfo.type}</div>
                            </div>
                                            
                            <div className="single_description">{this.state.clipInfo.description}</div>

                            <div className="single_separator" /> 
                            <div className="single_uploader">{this.state.clipInfo.uploader}</div>
                            
                        </div> 
                    </div>
                </div>
            </div>
        );
  }
}