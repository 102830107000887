import React from 'react';
import { Link } from 'react-router-dom';
import app from '../../firebase';


import './SideDrawer.css';

const sideDrawer = props => {

    let drawerClasses = 'side-drawer';

    if(props.show) {
        drawerClasses = 'side-drawer open';
    }

    return (
    <nav className={drawerClasses}>
        <h1 className="sidedrawer_title"><Link to="/" onClick={props.selectMap} id="home">Holysmokes</Link></h1>
        <ul>
            <li><Link to="/" onClick={props.selectMap} id="inferno">Inferno</Link></li>
            <li><Link to="/" onClick={props.selectMap} id="train">Train</Link></li>
            <li><Link to="/" onClick={props.selectMap} id="mirage">Mirage</Link></li>
            <li><Link to="/" onClick={props.selectMap} id="nuke">Nuke</Link></li>
            <li><Link to="/" onClick={props.selectMap} id="overpass">Overpass</Link></li>
            <li><Link to="/" onClick={props.selectMap} id="dust2">Dust II</Link></li>
            <li><Link to="/" onClick={props.selectMap} id="vertigo">Vertigo</Link></li>
            <li><Link to="/" onClick={props.selectMap} id="cache">Cache</Link></li>
            <li><Link to="/" onClick={props.selectMap} id="cobblestone">Cobblestone</Link></li>
                    
            <div className="side-drawer_spacer"/>
            <ul className="usertools hidden">
                    <li><Link to="/upload" onClick={props.click}>Upload</Link></li>
                    <li><Link to="/profile" onClick={props.click}>My Profile</Link></li>
                    <li><button className="toolbar_button" onClick={() => app.auth().signOut()}>Logout</button></li>
                </ul>
                <ul className="logintab">
                    <li><Link to="/auth" onClick={props.click}>Login / Register</Link></li>
            </ul>
        </ul>
    </nav>
    )
};

export default sideDrawer;