import React, { Component } from 'react';
import app from '../../firebase';
import { functions } from '../../firebase';
import { db } from '../../firebase';

import './Admin.css';

  
class AdminPage extends Component {

    state = {
        user: null,
        verified: null,
        isAdmin: null,
        email: null,
        clips: [],
    }

    constructor(props) {
        super(props);
        this.authListener = this.authListener.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.authListener();
        this.getClips();
    }

    authListener = () => {
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ 
                    user: user.displayName,
                    verified: user.emailVerified
                });
                
            } else {
                this.setState({ 
                    user: null,
                    verified: false
                })
            }
        });
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        
        this.setState({
          [name]: value
        });
    }

    // Get unverified clips

    getClips = () => {

        let tmpClips = [] 

        db.collection('clips')
        .where('verified', '==', false)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                tmpClips.push(doc.data());
                this.setState({ clips: tmpClips });
            });
        })
    }

    // Confirm clip for public use

    confirmClip = (uid) => {
        db.collection("clips").doc(uid).update({
            "verified": true
        })
    }

    // Delete clip

    deleteClip = (uid) => {

        let storage = app.storage();

        let storageRef = storage.ref()

        // Create a reference to the file to delete
        let clipRef = storageRef.child('clips/' + uid);

        // Delete the file
        clipRef.delete().then(() => {
            // File deleted successfully
        })
        .catch((error) => {
            // File deleted successfully
        });
    }

    // Mouse events from Main.js

    onMouseOutEvents = (event) => {
        let isPlaying = event.target.currentTime > 0 && !event.target.paused && !event.target.ended && event.target.readyState > 2

        if (isPlaying) {
            event.target.pause()
            event.target.currentTime = 0  
        }
        
    }

    onMouseOverEvents = (event) => {
        let isPlaying = event.target.currentTime > 0 && !event.target.paused && !event.target.ended && event.target.readyState > 2

        if (!isPlaying) {
            event.target.play();
        }
    }

    // Add admin

    addAsAdmin = (e) => {
        e.preventDefault();
        const addAdminRole = functions.httpsCallable('addAdmin');

        // Cloud function waits for 'data.email'

        addAdminRole({
            email: this.state.email
        }).then(response => {
            console.log(response);
        }).catch(e => {
            console.error(e);
        })
        
    }


    render() {
        return (
            <div>
                <div className="box shadow-lg">
                    <div className="upload-container">
                        <form className="upload-form admin-actions" autoComplete="off" onSubmit={this.addAsAdmin}>
                            <h1 className="auth-title">Admin board</h1>

                            <div className="form-control">
                                <h6 className="upload-item-name">Add as admin: </h6>
                                <input type="email" name="email" onChange={this.handleInputChange} />
                            </div>

                            <div className="form-actions">
                                <button className="upload-button" type="submit">Confirm</button>
                            </div>

                            
                        </form>
                    </div>       
                </div>
                    <div id="clips" className="box shadow-lg">
                    <h1 className="auth-title">Unverified clips</h1>
                    {this.state.clips.map(clip => 
                        <div className="admin_clip" key={clip.clipid}>
                            <div className="admin_clip_content">
                                <div className="admin_clip_thumbnail">
                                    <img
                                    className="ok" 
                                    src={clip.publicThumbUrl}
                                    alt={clip.clipid}
                                    />
                                    <video
                                    className="showme"
                                    preload="none"
                                    controls={true}
                                    onMouseOver={event => this.onMouseOverEvents(event)}
                                    onMouseOut={event => this.onMouseOutEvents(event)}
                                    >
                                        <source src={clip.publicUrl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    
                                </div>
                                <div className="admin_clip_details">
                                    <h4> {clip.title}</h4>
                                    <p>Map: {clip.map}</p>
                                    <p></p>Tickrate: {clip.tickrate}
                                    <p>Description: {clip.description}</p>
                                    <p>Utility: {clip.utility}</p>
                                    <p>Type: {clip.type}</p>
                                </div>
                            </div>
                            <div className="admin_buttons">
                                <button className="admin_verify" onClick={() => this.confirmClip(clip.clipid)}>CONFIRM</button>
                                <button className="admin_decline" onClick={() => this.deleteClip(clip.clipid)}>DELETE</button>
                            </div>
                        </div>
                    )}
                        
                    </div>
                
            </div>
            
        );
    }
}

export default AdminPage;