import React, { Component } from 'react';
import { db } from '../../firebase';
import ReactPlayer from 'react-player'

import './Clip.css';


class ClipPage extends Component {
    state = {
        clipInfo: []
    }

    componentDidMount() {
        this.getClip()
    }

    getClip() {
        let docRef = db.collection('clips').doc(this.props.match.params.id)

        docRef.get().then((doc) => {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                this.setState({
                    clipInfo: doc.data()
                })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        })
    }

    //TODO: Vaihda toi map setti johonki järkevämpään?
    render() {
        return (
            <div className="box shadow-lg">
                <div className='player-wrapper'>
                    <ReactPlayer
                        className='react-player'
                        url={this.state.clipInfo.publicUrl}
                        width='100%'
                        height='100%'
                        controls={true}
                    />
                </div>
                <div className="single_details">
                    <h2 className="single_title">{this.state.clipInfo.title}</h2>
                    <div className="single_uploader">{this.state.clipInfo.uploader}</div>
                    <div className="single_separator" /> 
                    <div className="single_row">
                        <div className="single_utility single_col">{this.state.clipInfo.utility}</div>
                        <div className="single_col">•</div>
                        <div className="single_type single_col">{this.state.clipInfo.type}</div>
                        <div className="single_col">•</div>
                        <div className="single_tick single_col">{this.state.clipInfo.tickrate}</div>
                        <div className="single_col">•</div>
                        <div className="single_map single_last">{this.state.clipInfo.map}</div>
                    </div>
                    
                    <div className="single_separator" /> 

                    

                    
                    <div className="single_description">{this.state.clipInfo.description}</div>
                    
                </div>
            </div>
        );
    }
}

export default ClipPage;