import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import app from '../../firebase';
import MainPage from '../Main';
import swal from 'sweetalert';


import './Auth.css';

class AuthPage extends Component {
    state = {
        isLogin: true,
        user: null
    }

    constructor(props) {
        super(props);
        this.userEl = React.createRef();
        this.emailEl = React.createRef();
        this.passwordEl = React.createRef();
        this.passwordEl2 = React.createRef();

        this.authListener = this.authListener.bind(this);
    }

    componentDidMount() {
        this.authListener();
    }

    authListener = () => {
        app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ user: user });
            } else {
                this.setState({ user: null })
            }
        });
    };

    switchModeHandler = () => {
        this.setState(prevState => {
            return {isLogin: !prevState.isLogin}
        })
    }

    signUp = (event) => {
            event.preventDefault();
            const userName = this.userEl.current.value;
            const email = this.emailEl.current.value;
            const password = this.passwordEl.current.value;
            const password2 = this.passwordEl2.current.value;
    
            if (password !== password2) {
                return;
            }

            if (email.trim().length === 0 || password.trim().length === 0 || userName.trim().length === 0) {
                return;
            }
            
            app.auth().createUserWithEmailAndPassword(email, password)
            .then((u) => {
                // Succesful user creation

                console.log('User created');

                u.user.sendEmailVerification()
                .then((u) => {
                    // Email sent

                    console.log('Verification sent');
                })
                .catch((err) => {
                    // Error sending verification email
                    document.getElementById('form-error').innerHTML = err.message;
                    document.getElementById('form-error').style.display = 'block';
                })

                app.auth().onAuthStateChanged(function(user) {

                    if (user) {
                        // Updates the user attributes:
        
                        user.updateProfile({ // <-- Update Method here
        
                        displayName: userName
                        //photoURL: "https://example.com/jane-q-user/profile.jpg"
        
                        })
                        .then(() => {
        
                            // Profile updated successfully!
                            //  "NEW USER NAME"
        
                            //var displayName = user.displayName;
                            // "https://example.com/jane-q-user/profile.jpg"
                            //var photoURL = user.photoURL;
        
                        })
                        .catch((err) => {
                            // An error happened.
                        });     
                    }
                });
            })
            .catch((err) => {
                // Error in user creation
                document.getElementById("form-error").innerHTML = err.message;
                document.getElementById("form-error").style.display = 'block';
            })
    }

    login = (event) => {
            event.preventDefault();
            const email = this.emailEl.current.value;
            const password = this.passwordEl.current.value;
    
            if (email.trim().length === 0 || password.trim().length === 0) {
                return;
            }

            app.auth().signInWithEmailAndPassword(email, password)
                .then((u) => {
                    console.log('Successfully Logged in')
                    this.props.history.push('/')
                })
                .catch((err) => {
                    this.passwordEl.current.value = ""
                    document.getElementById("form-error").innerHTML = 'E-mail and/or password do not match!'
                    document.getElementById("form-error").style.display = 'block'
                })
    };

    passwordInputHandler = () => {
        if (!this.state.isLogin) {
            document.getElementById('username').style.display = 'none';
            document.getElementById('password2').style.display = 'none';
        } else {
            document.getElementById('username').style.display = 'block';
            document.getElementById('password2').style.display = 'block';
        }
    };

    submitHandler = (event) => {
        if (!this.state.isLogin) {
            this.signUp(event)
        } else {
            this.login(event)
        }
    }

    forgetPasswordHandler = () => {
        swal("Enter your email to get a password recovery link:", {
            content: "input",
        })
        .then((value) => {
            app.auth().sendPasswordResetEmail(value).then(() => {
                // Email sent.
                swal("A password recovery link is sent if an user with this email is found: " + value, {
                    icon: "success",
                  });
            })
            .catch((err) => {
                // An error happened.
                // console.log(err);
                swal("A password recovery link is sent if an user with this email is found: " + value, {
                    icon: "success",
                  });
            });  
        });
    }

    render() {

        return (
            <div className="box shadow-lg">
                { this.state.user ? (<MainPage/>) : 
                    (<form className="auth-form" onSubmit={this.submitHandler}>
                        <h1 className="auth-title">{this.state.isLogin ? "Login" : "Register"}</h1>
                        <div className="form-control">
                            <input placeholder="Display name" type="text" id="username" ref={this.userEl} />
                        </div>
                        <div className="form-control">
                            <input placeholder="E-mail" type="email" id="email" ref={this.emailEl} />
                        </div>
                        <div className="form-control">
                            <input placeholder="Password" type="password" id="password" ref={this.passwordEl} />
                        </div>
                        <div className="form-control">
                            <input placeholder="Password again" type="password" id="password2" ref={this.passwordEl2} />
                        </div>
                        <div id="form-error" className="form-error" />
                        <div className="form-actions">
                            <button  type="submit">
                                Submit
                            </button>
                            <br />
                            <button className="switcher" type="button" onClick={() => {this.switchModeHandler(); this.passwordInputHandler();}}>
                                {this.state.isLogin ? "Don't have an account? Get started" : "Already registered? Click to login"}
                            </button>
                            <br />
                            <button className="switcher" type="button" onClick={() => this.forgetPasswordHandler()}>
                                Forgot password?
                            </button>
                        </div>
                    </form>)
                }
            </div>
        );
    }
}

export default withRouter(AuthPage);